import { navigate } from "gatsby";
import {
  Box,
  Button,
  Card,
  CheckBox,
  Image,
  InfiniteScroll,
  Layer,
  Menu,
  Select,
  TextInput,
} from "grommet";
import { Duplicate, Edit, Search, Trash, Upload } from "grommet-icons";
import React from "react";
import NewCatalog, {
  EditCatalogName,
} from "../../components/catalog/new_catalog";
import NewItemPopup from "../../components/common/new_item";
import { TypeToConfirmation } from "../../components/elements/confirmation_popup";
import { EventEmitter } from "../../components/elements/event_emitter";
import PrimaryButton from "../../components/elements/primary_button";
import StandardText, { COLOR } from "../../components/elements/standard_text";
import LoadingAnimation from "../../components/loading_animation";
import NavigationFrame from "../../components/nav_frame";
import {
  cascadeDeleteCatalog,
  duplicateCatalog,
  pushCatalog,
  saveCatalog,
  searchCatalog,
} from "../../service/catalog_service";
import { getPartners } from "../../service/partner_service";
import { hasAdminRole } from "../../service/storage_service";

import deliveroo from "../../images/deliveroo.png";
import uber from "../../images/uber.png";
import { PLATFORM } from "../../components/catalog/utils";

class Catalogs extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    this.state = {
      data: location.state?.data?.catalogs || undefined,
      confirmationPopup: undefined,
      loading: undefined,
      duplicate: undefined,
      rename: undefined,
      newItem: undefined,
      timerId: undefined,
      partners: [],
      selectedPartner: undefined,
      filter: {},
      pagination: location.state?.data?.pagination || {
        page: 1,
        pageCount: 1,
        total: 25,
        pageSize: 25,
      },
      selectedCatalogs: [],
      catalogsToDelete: undefined,
    };
  }

  componentDidMount = () => {
    getPartners().then((res) => {
      const partners = [{ id: null, name: "All partners" }, ...res.data.data];
      this.setState({ partners });
    });
    if (this.state.data?.length) return;
    this.loadCatalogs();
  };

  loadCatalogs = () => {
    if (hasAdminRole()) {
      searchCatalog({
        query: this.state.filter.query,
        partnerId: this.state.selectedPartner?.id,
      }).then((res) => {
        this.setState({
          data: res.data.data,
          pagination: { ...res.data.meta.pagination },
        });
      });
    }
  };

  deleteItem = (id) => {
    this.setState({ loading: true });
    cascadeDeleteCatalog(id).then((_) => {
      EventEmitter.dispatch("showMessage", {
        message: "Catalog is deleted successfully.",
      });
      this.setState({
        confirmationPopup: undefined,
        loading: false,
        data: this.state.data.filter((c) => c.id !== id),
      });
    });
  };

  duplicateItem = (item) => {
    let duplicate = this.state.duplicate;
    item.partner_name = duplicate.partner_name;
    item.partner_id = duplicate.partner_id;
    item.partner = duplicate.partner;
    this.setState({ loading: true, duplicate: undefined });
    duplicateCatalog(duplicate.id, item).then((res) => {
      const duplicated = res.data;
      duplicated.partner = duplicate.partner;
      this.state.data.push(duplicated);
      this.setState({
        loading: false,
        data: this.state.data.map((d) => d),
        duplicate: undefined,
      });
      EventEmitter.dispatch("showMessage", {
        message: "Catalog is duplicated successfully.",
      });
    });
  };

  createCatalog = async (item) => {
    const res = await searchCatalog({ query: item.salesforce_opportunity_id });
    if (res.data.data.length) {
      EventEmitter.dispatch("showMessage", {
        message:
          item.salesforce_opportunity_id +
          " exist in catalog " +
          res.data.data[0].name,
        messageType: "error",
      });
      return;
    }
    saveCatalog(item)
      .then((res) => {
        const data = this.state.data;
        data.push(res.data.data);
        this.setState({ data: data.map((d) => d), newItem: undefined });
        EventEmitter.dispatch("showMessage", {
          message: "Catalog is created successfully.",
        });
      })
      .catch(EventEmitter.errorHandler);
  };

  renameCatalog = (item) => {
    saveCatalog(item)
      .then((res) => {
        const data = this.state.data;
        const index = data.map((d) => d.id).indexOf(item.id);
        data[index] = item;
        this.setState({ data: data.map((d) => d), rename: undefined });
        EventEmitter.dispatch("showMessage", {
          message: "Catalog is saved successfully.",
        });
      })
      .catch(EventEmitter.errorHandler);
  };

  onSearch = (query) => {
    let timerId = this.state.timerId;
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(() => {
      getPartners(query).then((res) => {
        this.setState({ partners: res.data.data });
      });
    }, 350);
    this.setState({ timerId });
  };

  onMore = () => {
    if (this.state.pagination.total <= this.state.data.length) return;
    searchCatalog({
      query: this.state.filter.query,
      partnerId: this.state.selectedPartner?.id,
      page: this.state.pagination.page + 1,
    }).then((res) => {
      this.setState({
        data: [...this.state.data, ...res.data.data],
        pagination: { ...res.data.meta.pagination },
      });
    });
  };

  onCatalogSelectedChange = (catalog) => {
    catalog.selected = catalog.selected ? false : true;
    const catalogs = this.state.selectedCatalogs;
    if (catalogs.map((p) => p.id).includes(catalog.id)) {
      const index = catalogs.indexOf(catalog);
      catalogs.splice(index, 1);
    } else {
      catalogs.push(catalog);
    }
    this.setState({
      data: [...this.state.data],
      selectedCatalogs: [...catalogs],
    });
  };

  pushCatalogs = async (platform) => {
    this.setState({ loading: true });
    let i = 1;
    const catalogs = this.state.selectedCatalogs;
    for (const catalog of catalogs) {
      if (platform !== PLATFORM.ALL) {
        this.setState({
          loading:
            "Pushing catalog: " + catalog.name + ` (${i}/${catalogs.length})`,
        });
        await this.pushCatalog(catalog, platform);
      } else {
        this.setState({
          loading:
            "Pushing catalog: " +
            catalog.name +
            ` (${i}/${catalogs.length}) to UBER EAT`,
        });
        await this.pushCatalog(catalog, PLATFORM.UBER_EAT);
        this.setState({
          loading:
            "Pushing catalog: " +
            catalog.name +
            ` (${i}/${catalogs.length}) to DELIVEROO`,
        });
        await this.pushCatalog(catalog, PLATFORM.DELIVEROO);
      }
      i++;
    }
    this.setState({ loading: false });
  };

  pushCatalog = async (catalog, platform) => {
    try {
      await await pushCatalog(catalog.id, platform);
      return true;
    } catch (error) {
      return false;
    }
  };

  deleteAll = async () => {
    this.setState({ loading: true });
    let i = 1;
    const catalogs = this.state.selectedCatalogs;
    for (const catalog of catalogs) {
      this.setState({
        loading:
          "Deleting catalog: " + catalog.name + ` (${i}/${catalogs.length})`,
      });
      try {
        await cascadeDeleteCatalog(catalog.id);
        this.setState({
          data: this.state.data.filter((c) => c.id !== catalog.id),
        });
      } catch (error) {}
      i++;
    }
    this.setState({ loading: false, catalogsToDelete: undefined, selectedCatalogs: [] });
  };

  render() {
    return (
      <NavigationFrame>
        {this.state.data ? (
          <Box
            width={"xlarge"}
            pad="medium"
            style={{ height: "100vh", minHeight: "auto", overflowY: "auto" }}
            gap="medium"
          >
            <Box
              justify="between"
              width={"full"}
              pad={{ vertical: "small" }}
              direction="row"
              style={{ minHeight: "auto" }}
              align="center"
            >
              <Select
                placeholder="All partners"
                size="small"
                labelKey="name"
                valueKey={{ key: "id", reduce: true }}
                onSearch={(text) => {
                  this.onSearch(text);
                }}
                options={this.state.partners}
                onChange={({ value: nextValue }) => {
                  searchCatalog({ partnerId: nextValue }).then((res) =>
                    this.setState({ data: res.data.data })
                  );
                }}
                multiple={false}
              />
              <Box align="center">
                <TextInput
                  icon={<Search />}
                  placeholder="Search"
                  size="small"
                  value={this.state.filter.query || ""}
                  onChange={(e) =>
                    this.setState({ filter: { query: e.target.value } })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.loadCatalogs();
                    }
                  }}
                />
              </Box>
              <PrimaryButton
                label={"Nouveau catalog"}
                onClick={() => this.setState({ newItem: {} })}
              />
            </Box>
            <Box width={"full"} gap="small" style={{ minHeight: "auto" }}>
              {this.state.selectedCatalogs.length > 0 && (
                <PossibleActions
                  catalogs={this.state.selectedCatalogs}
                  onDeleteAll={() => this.setState({ catalogsToDelete: true })}
                  pushCatalogs={this.pushCatalogs}
                />
              )}
              <InfiniteScroll items={this.state.data} onMore={this.onMore}>
                {(catalog) => (
                  <LineView
                    catalog={catalog}
                    key={catalog.name}
                    onDelete={() =>
                      this.setState({ confirmationPopup: catalog })
                    }
                    onDuplicate={() => this.setState({ duplicate: catalog })}
                    onRename={() => this.setState({ rename: catalog })}
                    data={this.state.data}
                    pagination={this.state.pagination}
                    onSelectedChange={this.onCatalogSelectedChange}
                  />
                )}
              </InfiniteScroll>
            </Box>
            {this.state.confirmationPopup ? (
              <TypeToConfirmation
                description={
                  "This operation cannot be reverted. Do you want to proceed?"
                }
                close={() => this.setState({ confirmationPopup: undefined })}
                pharse={this.state.confirmationPopup.name}
                confirmDelete={() =>
                  this.deleteItem(this.state.confirmationPopup.id)
                }
              />
            ) : null}
            {this.state.catalogsToDelete ? (
              <TypeToConfirmation
                description={
                  "This operation cannot be reverted. Do you want to proceed?"
                }
                close={() => this.setState({ catalogsToDelete: undefined })}
                pharse={"Catalogs"}
                confirmDelete={() => this.deleteAll()}
              />
            ) : null}
            {this.state.duplicate ? (
              <Layer>
                <Box width={"medium"} justify="center" align="center">
                  <NewItemPopup
                    label={"Duplicate catalog"}
                    onClose={() => this.setState({ duplicate: undefined })}
                    onCreate={this.duplicateItem}
                  />
                </Box>
              </Layer>
            ) : null}
            {this.state.newItem ? (
              <Layer>
                <Box width={"medium"} justify="center" align="center">
                  <NewCatalog
                    label={"New catalog"}
                    onClose={() => this.setState({ newItem: undefined })}
                    onCreate={this.createCatalog}
                    all_partners={this.state.partners}
                  />
                </Box>
              </Layer>
            ) : null}
            {this.state.rename ? (
              <Layer>
                <Box width={"medium"} justify="center" align="center">
                  <EditCatalogName
                    onClose={() => this.setState({ rename: undefined })}
                    onEdit={this.renameCatalog}
                    catalog={this.state.rename}
                  />
                </Box>
              </Layer>
            ) : null}
            {this.state.loading ? (
              <Layer>
                <Box
                  justify="center"
                  align="center"
                  style={{ width: 300, height: 300 }}
                >
                  <LoadingAnimation text={this.state.loading} />
                </Box>
              </Layer>
            ) : null}
          </Box>
        ) : (
          <LoadingAnimation />
        )}
      </NavigationFrame>
    );
  }
}

const LineView = ({
  catalog,
  onDelete,
  onDuplicate,
  onRename,
  data,
  pagination,
  onSelectedChange,
}) => (
  <Card
    round="xsmall"
    width={"full"}
    background={{ color: "#FFF" }}
    pad={"xsmall"}
    justify="between"
    direction="row"
    style={{ minHeight: "auto" }}
    margin={{ bottom: "small" }}
  >
    <Box align="center" direction="row" pad={{ left: "small" }} gap="small">
      <CheckBox
        checked={catalog.selected}
        onChange={(_) => onSelectedChange(catalog)}
      />
      <Box align="center" direction="row" pad={{ left: "small" }}>
        <StandardText
          label={`${catalog.name} `}
          style={{ fontWeight: "bold" }}
        />
        &nbsp;
        <StandardText label={` - `} />
        &nbsp;
        <StandardText
          label={` ${catalog.partner_name}`}
          color="#999"
          style={{ fontWeight: "bold" }}
        />
      </Box>
    </Box>
    <Box align="center" direction="row" pad={{ left: "small" }} gap="small">
      <Menu
        color={"brand"}
        label={
          <StandardText
            color="brand"
            label="Actions"
            style={{ fontWeight: "bold" }}
          />
        }
        size="small"
        ropProps={{
          a11yTitle: "Simple drop content",
          align: { top: "bottom", left: "left" },
          elevation: "xlarge",
        }}
        items={[
          {
            label: (
              <MenuItem
                label={"Dupplicate"}
                icon={<Duplicate size="small" />}
              />
            ),
            onClick: onDuplicate,
          },
          {
            label: <MenuItem label={"Rename"} icon={<Edit size="small" />} />,
            onClick: onRename,
          },
          {
            label: <MenuItem label={"Delete"} icon={<Trash size="small" />} />,
            onClick: onDelete,
          },
        ]}
      />
      <Button
        icon={<Edit size="small" />}
        label={<StandardText size="small" label={"Edit"} />}
        onClick={() =>
          navigate(`/catalogs/${catalog.id}?fromCatalogs`, {
            state: {
              data: {
                catalogs: data,
                pagination: pagination,
              },
            },
          })
        }
      />
      <PrimaryButton
        label={"Open"}
        onClick={() => navigate(`/catalogs/${catalog.id}/products`)}
      />
    </Box>
  </Card>
);

const MenuItem = ({ label, icon }) => (
  <Box direction="row" gap="small" align="center">
    {icon}
    <StandardText label={label} />
  </Box>
);

const PossibleActions = ({ catalogs, onDeleteAll, pushCatalogs }) => (
  <Box direction="row" gap="small" align="center">
    <StandardText label={`${catalogs.length} catalogs selected`} />
    <Box
      direction="row"
      align="center"
      justify="center"
      gap="small"
      style={{ minHeight: "auto" }}
    >
      <Box direction="row" align="center" justify="center" gap="small">
        <Button
          icon={<Trash size="small" color={COLOR.error} />}
          label={<StandardText label={"Delete all"} size="small" bold />}
          onClick={onDeleteAll}
        />
      </Box>
      <Menu
        dropProps={{
          align: { top: "bottom", left: "left" },
        }}
        items={[
          {
            label: (
              <Box align="center" direction="row" gap="xsmall">
                <Image src={deliveroo} style={{ height: 24 }} />
                <StandardText label={"Deliveroo"} bold size="xsmall" />
              </Box>
            ),
            onClick: () => pushCatalogs(PLATFORM.DELIVEROO),
          },
          {
            label: (
              <Box align="center" direction="row" gap="xsmall">
                <Image src={uber} style={{ height: 24 }} />
                <StandardText label={"Uber Eat"} bold size="xsmall" />
              </Box>
            ),
            onClick: () => pushCatalogs(PLATFORM.UBER_EAT),
          },
          {
            label: (
              <Box align="center" direction="row" gap="xsmall">
                <StandardText label={"All"} bold size="xsmall" />
              </Box>
            ),
            onClick: () => pushCatalogs(PLATFORM.ALL),
          },
        ]}
      >
        <Box direction="row" align="center" justify="center" gap="small">
          <Button
            icon={<Upload size="small" color={COLOR.green} />}
            label={<StandardText label={"Push catalog"} size="small" bold />}
          />
        </Box>
      </Menu>
    </Box>
  </Box>
);

export default Catalogs;
