import { Box, Layer, Select } from "grommet";
import { Close } from "grommet-icons";
import React, { useState } from "react";
import { getPartners } from "../../service/partner_service";
import CustomInput from "../elements/custom_input";
import PrimaryButton from "../elements/primary_button";
import StandardText, { COLOR } from "../elements/standard_text";

const NewCatalog = ({ label, onClose, onCreate, all_partners = [] }) => {

    const [item, setItem] = useState({});
    const [partners, setPartners] = useState(all_partners.filter(s => s.name !== "All partners"));
    const [timerId, setTimerId] = useState();
    const [error, setError] = useState();

    const onSearch = (query) => {
        if (timerId) clearTimeout(timerId);
        const _timerId = setTimeout(() => {
            getPartners(query)
                .then(res => setPartners(res.data.data));
        }, 350);
        setTimerId(_timerId);
    }

    const _onCreate = () => {
        if (!item.name || !item.partner || !item.salesforce_opportunity_id) {
            setError('All fields are required');
            return;
        }
        if (!(item.name?.length > 2)) {
            setError('Name has at least 3 characters');
            return;
        }
        onCreate(item);
    }

    return (
        <Layer>
            <Box width={'medium'} justify='center' align="center">
                <Box width={'full'} border={'bottom'}>
                    <Box direction="row" pad={'small'} justify='between' align="center">
                        <StandardText label={label} style={{ fontWeight: 'bold' }} />
                        <Close onClick={onClose} size='small' style={{ cursor: 'pointer' }} />
                    </Box>

                </Box>
                <Box pad={'small'} width='full' gap="small">
                    <StandardText label={"Name"} />
                    <CustomInput
                        value={item.name}
                        onChange={event => {
                            item.name = event.target.value;
                            setItem({ ...item });
                        }}
                        border={'auto'}
                        size='small'
                    />
                </Box>
                <Box pad={'small'} width='full' gap="small">
                    <StandardText label={"Partner"} />
                    <Select
                        placeholder="Partner"
                        size="small"
                        labelKey="name"
                        valueKey={{ key: 'id', reduce: true }}
                        onSearch={(text) => {
                            onSearch(text);
                        }}
                        options={partners}
                        onChange={({ value: nextValue }) => {
                            const _partner = partners.find(c => c.id === nextValue);
                            item.partner = _partner;
                            item.partner_name = _partner.name;
                            item.partner_id = _partner.id;
                            setItem({ ...item });
                        }}
                        multiple={false}
                    />
                </Box>

                <Box pad={'small'} width='full' gap="small">
                    <StandardText label={"Opportunity ID"} />
                    <CustomInput
                        value={item.salesforce_opportunity_id}
                        onChange={event => {
                            item.salesforce_opportunity_id = event.target.value;
                            setItem({ ...item });
                        }}
                        border={'auto'}
                        size='small'
                    />
                </Box>
                <Box pad={'small'} width='full' gap="small">
                    {error ? <StandardText style={{ fontStyle: 'italic' }} color={COLOR.error} size='xsmall' label={error} /> : null}
                </Box>
                <Box direction="row" justify="end" align="center" gap="small" pad={'medium'}>
                    <PrimaryButton
                        label={"Cancel"}
                        background={COLOR.background}
                        onClick={onClose}
                        color={COLOR.black}
                    />
                    <PrimaryButton
                        label={"Create"}
                        onClick={_onCreate}
                        background='brand'
                    />
                </Box>
            </Box>
        </Layer>
    )
}

const EditCatalogName = ({ catalog, onClose, onEdit }) => {
    const [item, setItem] = useState({...catalog});
    const [error, setError] = useState();

    const _onEdit = () => {
        if(!(item.name?.length > 2)) {
            setError('Name has at least 3 characters');
            return;
        }
        onEdit(item);
    }
    return (
        <Layer>
            <Box width={'medium'} justify='center' align="center">
                <Box width={'full'} border={'bottom'}>
                    <Box direction="row" pad={'small'} justify='between' align="center">
                        <StandardText label={"Edit name"} style={{ fontWeight: 'bold' }} />
                        <Close onClick={onClose} size='small' style={{ cursor: 'pointer' }} />
                    </Box>

                </Box>
                <Box pad={'small'} width='full' gap="small">
                    <StandardText label={"Name"} />
                    <CustomInput
                        value={item.name}
                        onChange={event => {
                            item.name = event.target.value;
                            setItem({ ...item });
                        }}
                        border={'auto'}
                        size='small'
                        onKeyDown={event => {
                            if(event.key === "Enter") _onEdit();
                        }}
                    />
                </Box>
                <Box pad={'small'} width='full' gap="small">
                    {error ? <StandardText style={{ fontStyle: 'italic' }} color={COLOR.error} size='xsmall' label={error} /> : null}
                </Box>
                <Box direction="row" justify="end" align="center" gap="small" pad={'medium'}>
                    <PrimaryButton
                        label={"Cancel"}
                        background={COLOR.background}
                        onClick={onClose}
                        color={COLOR.black}
                    />
                    <PrimaryButton
                        label={"Save"}
                        onClick={_onEdit}
                        background='brand'
                    />
                </Box>
            </Box>
        </Layer>
    )
}

export default NewCatalog;

export {
    EditCatalogName
};
